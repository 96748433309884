<template>
    <div class="x-page-container" ref="resize">
        <div class="page-tips">
            <p>默认显示当前月数据</p>
        </div>
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="交易时间" textWidth="400px">
                <x-date-between separator="~" :start.sync="search.jiaoYiSJBegin" :end.sync="search.jiaoYiSJEnd"
                                type="datetimerange" size="small"/>
            </x-search-item>
            <x-search-item label="货号/条码">
                <el-input v-model="search.huoHao" size="small"/>
            </x-search-item>
            <x-search-item label="商品分类">
                <el-cascader :show-all-levels="false" size="small" style="width: 100%" v-model="search.shangPinFenLei"
                             :options="fenLeiOptions"
                             :props="fenLeiProps">
                </el-cascader>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                <excel-export :tableData="exportTableData" :headAndKey="headAndKey" :isSum="true" :condition="condition"
                              fileName="商品销售汇总">
                    <el-button type="success" size="mini" style="margin-left: 10px">导出</el-button>
                </excel-export>
            </div>
        </div>
        <div class="table-top-card-box">
            <x-card class="shou-kuan-card" label="销售总额" :text="cardData.xsze"></x-card>
            <x-card class="shou-kuan-card" label="商品总数" :text="cardData.spzs"></x-card>
            <x-card class="shou-kuan-card" label="成本合计" :text="cardData.cbhj"></x-card>
            <x-card class="shou-kuan-card" label="利润合计" :text="cardData.lrhj"></x-card>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="shangPinMC" label="商品名称"/>
            <el-table-column width="180" prop="shangPinFenLeiMC" label="商品分类"/>
            <el-table-column width="180" prop="huoHao" label="货号"/>
            <el-table-column width="180" prop="tiaoMa" label="条码"/>
            <el-table-column width="180" prop="danWei" label="单位"/>
            <el-table-column width="180" prop="shouChuSL" label="售出数量"/>
            <el-table-column width="180" prop="shouChuZE" label="售出总额"/>
            <el-table-column width="180" prop="chengBenHJ" label="成本合计"/>
            <el-table-column width="180" prop="liRunHJ" label="利润合计"/>
        </el-table>
        <span style="margin: 5px 3px; color: rgb(96, 98, 102);">共 {{tableDateSize}} 条</span>
    </div>
</template>
<script>
    import * as service from "@/service/jygl/ShangPinXiaoShouMX";
    import XTableBase from "@/components/x/XTableBase";
    import XCard from "@/components/x/card/XCard";
    import {deepCopy, CustomMath} from "@/util/objects";
    import {mainTree} from "@/service/spgl/ShangPinFenLei"
    import XDateBetween from "@/components/x/sc/XDateBetween.vue";

    export default {
        name: "ShangPinXiaoShouHZList",
        mixins: [XTableBase],
        components: {XCard,XDateBetween},
        beforeCreate() {
            this.defaultCardData = {
                xsze: 0,
                spzs: 0,
                cbhj: 0,
                lrhj: 0,
            };
        },
        data() {
            this.refreshService = service.getShangPinXSHZ;
            this.service = service;
            return {
                search: {
                    huoHao: '',
                    shangPinFenLei: '',
                    jiaoYiSJBegin: '',
                    jiaoYiSJEnd: '',
                },
                headAndKey: {
                    shangPinMC: "商品名称",
                    shangPinFenLeiMC: "商品分类",
                    huoHao: "货号",
                    tiaoMa: "条码",
                    danWei: "单位",
                    shouChuSL: "售出数量",
                    shouChuZE: "售出总额",
                    chengBenHJ: "成本合计",
                    liRunHJ: "利润合计",
                },
                fenLeiOptions: [],
                tableDateSize:0,//列表数据条数
                fenLeiProps: {
                    emitPath: false,
                    value: "id",
                    label: "name",
                    children: "subNodes",
                    checkStrictly: false,
                    multiple: false
                },
                exportTableData: [],
                condition: [],
                cardData: deepCopy(this.defaultCardData),
            }
        },
        created() {
          this.getFenLeiOptions()
        },
        methods: {
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                if (!this.search.jiaoYiSJBegin && !this.search.jiaoYiSJEnd) {
                    let nowDate = new Date()
                    let firstDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1)
                    this.search.jiaoYiSJBegin = firstDate
                    this.search.jiaoYiSJEnd = nowDate
                }
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    this.table.data = response.data;
                    if (this.table.data) {
                        let xsze = 0,spzs = 0,cbhj = 0,lrhj = 0;
                        this.table.data.forEach(item =>{
                            xsze = CustomMath.add2(xsze,item.shouChuZE)
                            spzs = CustomMath.add2(spzs,item.shouChuSL)
                            cbhj = CustomMath.add2(cbhj,item.chengBenHJ)
                            lrhj = CustomMath.add2(lrhj,item.liRunHJ)
                        })
                        this.cardData.xsze = xsze
                        this.cardData.spzs = spzs
                        this.cardData.cbhj = cbhj
                        this.cardData.lrhj = lrhj
                        this.tableDateSize = this.table.data.length
                    }else{
                        this.tableDateSize = 0
                        this.cardData = deepCopy(this.defaultCardData)
                    }
                    this.exportTableData = deepCopy(this.table.data)
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
            //获取分类
            getFenLeiOptions() {
                mainTree().then((response) => {
                    this.fenLeiOptions = response.data
                })
            },
        },
    }
</script>

<style scoped>
    .page-tips {
        padding: 8px 16px;
        background-color: #ecf8ff;
        border-radius: 4px;
        border-left: 5px solid #409EFF;
        font-size: 13px;
        color: #5e6d82;
        line-height: 1.5em;
        margin-bottom: 10px;
    }

    .table-top-card-box {
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;
        /* justify-content: space-between; */
    }

    .shou-kuan-card {
        width: 11%;
    }
</style>
